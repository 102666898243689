export const HEAD_TEMP_DATA = {
  title: 'Antadaa',
  description: 'Antadaa company website',
  ogTitle: 'Antadaa',
  ogDescription: 'Antadaa company website',
  ogType: 'website',
  ogImage: {
    url: '',
  },
};
